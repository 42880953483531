import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'

import { PermissionDenied } from 'django-rest-react'
import {
  displayDisplayable,
  displayEvento, displayPartecipante, nice_date,
} from '../utils'
import { canSeeShortlist, login_url } from '../globals'
import { useShortlist, useLogin, useEvento, usePartecipante, useThread } from '../reducers'
import { EVENTO_EVENTO_TIPOLOGIE } from '../globals/auto-enums';

type IProps = any


const threadToRow = (thread: ReturnType<typeof useShortlist>[0], eventi: ReturnType<typeof useEvento>) => {
  const ultimo = thread.shortlist.versioni[thread.shortlist.versioni.length - 1];
  const evento = !!thread.shortlist.evento ?
    eventi.find(ev => ev.id === thread.shortlist.evento)
    : null;

  return (
    <tr key={thread.id}>
      <td>
        <Link to={`/shortlist/${thread.id}/`}>
          {thread.titolo}
        </Link>
      </td>
      <td>{evento &&
        <Link to={`/thread/problema/evento/${evento.id}/`}>
          {displayEvento(evento.id)}
          <br />
          {nice_date(new Date(evento.data))}
        </Link>}
      </td>
      <td>
        {!!evento && displayDisplayable(evento.tipologia, EVENTO_EVENTO_TIPOLOGIE)}
      </td>
      <td>
        <Link to={`/utente/${thread.autore}/`}>
          {displayPartecipante(thread.autore)}
          <br />
          {nice_date(thread.data_inizio)}
        </Link>
      </td>
      <td>
        <Link to={`/utente/${ultimo.ultimo_revisore}/`}>
          {displayPartecipante(ultimo.ultimo_revisore)}
          <br />
          {nice_date(ultimo.data)}
        </Link>
      </td>
      <td>
        <Link to={`/shortlist/build/${thread.id}/testo/`} className="btn btn-success" title="TeX testo"><span className="fa fa-hammer" /> </Link>
        <Link to={`/shortlist/build/${thread.id}/soluzioni/`} className="btn btn-info" title="TeX soluzioni"><span className="fa fa-hammer" /> </Link>
      </td>
    </tr>
  );
}


const threadListToTable = (sorted: ReturnType<typeof useShortlist>, eventi: ReturnType<typeof useEvento>) => {
  return (
    <div className="table-responsive my-3 px-5">
      <table className="table table-bordered table-hover table-striped">
        <thead className="thead-dark">
          <tr>
            <th>Titolo</th>
            <th>Evento</th>
            <th>Tipo</th>
            <th>Autore</th>
            <th>Ultima modifica</th>
            <th>Build</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map(thread => threadToRow(thread, eventi))}
        </tbody>
      </table>
    </div>

  )
}

const ListaShortlist = (props: IProps) => {
  const login = useLogin();
  useEffect(() => {
    document.title = `Shortlists - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  if (!canSeeShortlist(login.user)) {
    throw new PermissionDenied({
      message: "Non hai abbastanza permessi per vedere le shortlist."
    })
  }

  const shortlist = useShortlist(props);
  const eventi = useEvento(props);
  const partecipanti = usePartecipante(props)
  const thread = useThread(props);


  const sorted = shortlist.sort((s1, s2) => {
    const e1 = eventi.find(ev => ev.id === s1.shortlist.evento)
    const e2 = eventi.find(ev => ev.id === s2.shortlist.evento)
    if (!!e1 && !!e2) {
      const d1 = new Date(e1.data);
      const d2 = new Date(e2.data);
      return d2.getTime() - d1.getTime();
    }
    return s2.id - s1.id;
  })

  const adesso = new Date();
  let i;
  for (i = sorted.length - 1; i >= 0; i--) {
    const thread = sorted[i];
    const e = eventi.find(ev => ev.id === thread.shortlist.evento)
    if (!e) continue;
    const d = new Date(e.data);
    if (d.getTime() > adesso.getTime()) break;
  }


  return (
    <div>
      <div className="container">
        <h1 className="page-header">Lista delle shortlist</h1>

        <Link to="/shortlist/new/">
          <button className="btn btn-success">
            <i className="fa fa-plus" /> Nuova shortlist
          </button>
        </Link>
        <h2>Shortlist per eventi nel futuro</h2>
      </div>
      {threadListToTable(sorted.slice(0, i + 1), eventi)}

      <div className="container">
        <h2>Shortlist per eventi passati</h2>
      </div>
      {threadListToTable(sorted.slice(i + 1, sorted.length), eventi)}
    </div>
  )
}


export default ListaShortlist;
