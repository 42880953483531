import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { isEqual } from 'lodash'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { build_tex, patch_new_problema, refresh_thread_problema_list, patch_versione_shortlist, refresh_shortlist } from '../api'
import { connectBuildNotification, useWebsocket } from '../api/websocket'
import { useShortlist, useThread } from '../reducers'
import { LoadingCircle, ConfirmedButton, FormGroupBootstrap, ErrorBlockGeneric } from '../components'
import store from '../store';
import { add_toast_action } from '../actions'
import { handle_drf_form_error } from 'django-rest-react';
import { Link } from 'react-router-dom';


interface FormValues {
  testi: string[],
  titoli: string[],
  sub_button: "build" | "save" | "reorder",
  clean_build: boolean,
  ordine: string,
}


const testo_getter = (testo: boolean, problema: ReturnType<typeof useThread>[0]["problema"]) => {
  if (testo) return problema.versioni[problema.versioni.length - 1].testo
  return problema.soluzione_spiegata
}

const submit_modifications = (testo_view: boolean, thread: ReturnType<typeof useThread>[0], testo: string, titolo: string) => {
  const data = testo_view ? {
    problema: {
      titolo: titolo,
      versioni: [{ testo: testo, }]
    }
  }
    : {
      problema: {
        soluzione_spiegata: testo,
        titolo: titolo,
      }
    }
  return patch_new_problema(thread.id, data)

}


const list_to_string = (arr: number[]) => arr.map(val => val.toString()).join(",")
const string_to_list = (s: string) => s.split(",").map(val => parseInt(val, 10))


function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const prevention: Parameters<typeof addEventListener>[1] = (event) => {
  event.preventDefault();
}



const OverleafShortlist = (props: {}) => {

  const location = useLocation();
  const params = useParams<{ pk: string }>();

  const testo_view = location.pathname.includes("testo");

  const thread_pk = parseInt(params.pk);
  const shortlists = useShortlist(props);
  const threads = useThread(props);
  const [seq, setSeq] = useState(0);
  const [error, setError] = useState(null);
  const [latexError, setLatexError] = useState(null);

  const ws = useWebsocket();
  const pdf_link = testo_view ? "testo" : "soluzioni";
  const build_toast = {
    title: "Compilazione richiesta",
    message: "La compilazione del file TeX è in corso, si ricarica automaticamente.",
    generating_object: `shortlist-build-${pdf_link}`
  }

  useEffect(() => {
    document.title = `Overleaf #${params.pk} - Owlitrack`;
    connectBuildNotification(ws.current, pdf_link, thread_pk, () => setSeq(prev => prev + 1), build_toast, setLatexError);
  }, [])

  const thread_short = shortlists.find(short => short.id == thread_pk);
  if (!thread_short) {
    return <LoadingCircle />
  }
  const pk = thread_short.shortlist.id;
  const versioni = thread_short.shortlist.versioni;
  const versione = versioni[versioni.length - 1];

  return (
    <div>
      <h1 className="page-header text-center">Overleaf dei poveri</h1>
      <div className="container">
        <h5>Leggimi</h5>
        <p>Per compilare il TeX al volo, premi sul tasto "compila", la preview verrà aggiornata automaticamente. Questo pulsante <b>non</b> salva le modifiche nel database, se vuoi che vengano salvate c'è il bottone in fondo alla pagina per salvare. Questo bottone aggiorna il testo di tutti e soli i problemi che hai modificato. Salvare nel database <b>non</b> triggera una ricompilazione del TeX. Da questa pagina si può cambiare solo il testo dei problemi, non il valore delle risposte, titolo o altro.</p>
        <p className="text-danger">Non mischiare cambio dei testi e riordino se non vuoi perdere modifiche, fai una cosa, refresha e poi fai l'altra, tanto non le farai contemporaneamente.</p>

        <div className="justify-content-center">
          <Link to={`/shortlist/${thread_short.id}/`} className="btn btn-primary">
            Dettagli shortlist
          </Link>
          {!testo_view &&
            <Link to={`/shortlist/build/${thread_short.id}/testo/`} className="btn btn-success" title="TeX testo"><span className="fa fa-hammer" /> Overleaf testo</Link>
          }
          {testo_view &&
            <Link to={`/shortlist/build/${thread_short.id}/soluzioni/`} className="btn btn-info" title="TeX soluzioni"><span className="fa fa-hammer" /> Overleaf soluzioni </Link>
          }

        </div>
      </div>
      <div className="row mr-0 ml-0">
        <div className="col-sm ml-4">
          <h2 className="page-header">{testo_view ? "Testo" : "Soluzioni"} della shortlist '{thread_short.titolo}'</h2>
          <ErrorBlockGeneric error={error} />
          <Formik
            enableReinitialize
            initialValues={{ ordine: versione.ordine }}
            onSubmit={() => { }}
          >
            {(outer) => {

              const { ordine } = outer.values;
              const thread_giusti = string_to_list(ordine).map(threadpk => threads.find(thr => thr.id == threadpk))
              if (!thread_giusti.map(thr => !!thr).reduce((acc, val) => acc && val, true)) {
                return <LoadingCircle />
              }
              const initial = {
                testi: thread_giusti.map(thr => testo_getter(testo_view, thr.problema)),
                titoli: thread_giusti.map(thr => thr.problema.titolo),
                sub_button: "build",
                clean_build: false,
              }
              return (
                <Formik
                  enableReinitialize
                  initialValues={initial}
                  onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    setError(null);
                    if (values.sub_button == "build") {
                      build_tex(thread_pk, {
                        testi: values.testi,
                        clean: values.clean_build,
                        build_type: testo_view ? "testo" : "soluzioni",
                        ordine: outer.values.ordine,
                      })
                        .then(() => store.dispatch(add_toast_action(build_toast)))
                        .catch(e => handle_drf_form_error(e, actions, setError))
                    } else if (values.sub_button == "save") {

                      let errors = [] as any[];
                      const responses = values.testi.map((testo, idx) => {
                        const thread = thread_giusti[idx];
                        const oldtitolo = thread_giusti[idx].titolo;
                        if (testo_getter(testo_view, thread.problema) != testo || values.titoli[idx] != oldtitolo) {
                          return submit_modifications(testo_view, thread, testo, values.titoli[idx]);
                        } else {
                          return new Promise(resolve => resolve(null))
                        }
                      })
                      for (const response of responses) {
                        try {
                          await response;
                        } catch (err) {
                          errors.push(err)
                        }
                      }
                      if (errors.length > 0) {
                        setError(errors)
                      } else {
                        store.dispatch(add_toast_action({
                          title: "Modifiche salvate",
                          message: "Le modifiche alla shortlist sono state salvate",
                        }))
                      }
                      refresh_thread_problema_list();
                    } else if (values.sub_button == "reorder") {
                      patch_versione_shortlist(thread_short.shortlist.id, {
                        versioni: [{
                          ordine: outer.values.ordine,
                        }]
                      })
                        .then(() => {
                          store.dispatch(add_toast_action({
                            title: "Modifiche salvate", message: "Ordine della shortlist cambiato e salvato sul server.",
                          }))
                          refresh_shortlist();
                        }
                        )
                        .catch(e => handle_drf_form_error(e, actions, setError))

                    } else {
                      console.error("Unreachable", values.sub_button)
                    }
                    actions.setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, handleReset, handleSubmit, setFieldValue, values }) => {
                    const modified = !isEqual(initial.testi, values.testi) || !isEqual(initial.titoli, values.titoli);
                    if (modified) {
                      addEventListener("beforeunload", prevention);
                    } else {
                      removeEventListener("beforeunload", prevention);
                    }

                    const onDragEnd = (result: DropResult) => {
                      // dropped outside the list
                      if (!result.destination) {
                        return;
                      }
                      const neworder = list_to_string(reorder(
                        thread_giusti,
                        result.source.index,
                        result.destination.index
                      ).map(thread => thread.id))
                      outer.setFieldValue("ordine", neworder)
                    }
                    const extra_bg = modified ? "bg-warning" : "";
                    return (
                      <Form className="form needs-validation">

                        <div className="d-flex justify-content-end">
                          <button className="btn btn-warning" type="button" disabled={isSubmitting}
                            onClick={() => {
                              setFieldValue("clean_build", true);
                              setFieldValue("sub_button", "build");
                              handleSubmit();
                            }}
                          >
                            Build clean
                          </button>
                          <button className="btn btn-success" type="button" disabled={isSubmitting}
                            onClick={() => {
                              setFieldValue("clean_build", false);
                              setFieldValue("sub_button", "build");
                              handleSubmit();
                            }}
                          >
                            Build
                          </button>
                        </div>

                        <div className={`overflow-auto pl-2 ${extra_bg}`} style={{ maxHeight: '800px' }}>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="testo_problemi">
                              {(provided, snapshot) =>
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {thread_giusti.map((thread, idx) => {
                                    const modified = values.testi[idx] != testo_getter(testo_view, thread.problema);
                                    const testo_before = <span>{modified && <i className="fa fa-edit text-danger" />} P{idx + 1}</span>;

                                    // La key indicata sotto è necessaria, se si usa idx o thread.id e basta,
                                    // quando vengono scambiati react si kekka e non procede a renderizzare nuovamente
                                    // i componenti, rompendo il drag-n-drop

                                    return (
                                      <Draggable key={thread.id * 10000 + idx} draggableId={thread.id.toString()} index={idx}>
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="d-flex justify-content-center">
                                                <span className="text-center" title="Drag and drop per spostare problemi">
                                                  <i className="fa fa-bars" />
                                                </span>
                                              </div>
                                              <div>
                                                <FormGroupBootstrap
                                                  type="text" name={`titoli[${idx}]`}
                                                  displayName="Titolo serio"
                                                />
                                                <FormGroupBootstrap
                                                  type="textarea" name={`testi[${idx}]`}
                                                  displayName={testo_before}
                                                />
                                              </div>
                                            </div>
                                          )
                                        }}
                                      </Draggable>
                                    )
                                  })}
                                  {provided.placeholder}
                                </div>
                              }
                            </Droppable>
                          </DragDropContext>

                        </div>
                        <div className="d-flex justify-content-end">
                          <ConfirmedButton
                            type="warning"
                            onSubmit={() => { handleReset(); outer.handleReset() }}
                          >
                            Reset
                          </ConfirmedButton>
                          <button className="btn btn-success" type="button"
                            disabled={outer.values.ordine == versione.ordine}
                            onClick={() => { setFieldValue("sub_button", "reorder"); handleSubmit(); }}
                          >
                            Riordina
                          </button>
                          <button className="btn btn-success" type="button"
                            disabled={!modified}
                            onClick={() => { setFieldValue("sub_button", "save"); handleSubmit(); }}
                          >
                            Salva le modifiche
                          </button>
                        </div>
                        <ErrorBlockGeneric error={error} />
                      </Form>
                    )
                  }}
                </Formik>
              )
            }}
          </Formik>
        </div>
        <div className="col-sm">
          <embed key={seq} src={`/shortlist/${pdf_link}-build/${pk}/`} type="application/pdf" width="100%" height="100%" />
        </div>
      </div>
      <div className="container">
        {latexError &&
          <pre className="pre-scrollable" style={{ maxHeight: '600px' }}>
            {latexError}
          </pre>
        }
      </div>
    </div>
  )
}


export default OverleafShortlist
