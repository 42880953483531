import React, { useState } from 'react';
import { useFormikContext, getIn } from 'formik';

import { LinterSuggerimento, LaTeX, RealTeXLoad } from './'
import { Linter } from '../api/types'
import { build_tex_problema } from '../api';

type CallFunc = (val: Parameters<typeof build_tex_problema>[1]) => ReturnType<typeof build_tex_problema>;


interface IProps {
  fieldname: string,
  lints?: Linter[],
  latex_api?: CallFunc,
}

const AnteprimaLaTeX = (props: IProps) => {
  const [iteration, setIteration] = useState(0);
  const { fieldname, lints } = props;
  const { values } = useFormikContext();
  const text = getIn(values, props.fieldname);

  return (
    <div className="card my-1">
      <div className="card-body">
        <h5 className="card-title">Anteprima</h5>
        <small>LaTeX è attivo. Digita nel form sottostante e vedrai in anteprima i
          messaggi con le formule renderizzate se inserite fra tag <code className="text-danger">
            $ $</code> (inline)
          o <code className="text-danger">\[ \]</code>
        </small>
        <br />
        <br />
        {!!props.latex_api &&
          <>
            <RealTeXLoad id={1} api={() => props.latex_api({ override: text })} callback={() => { }} force_refresh={iteration} />
            <button className="btn btn-info" type="button" onClick={() => setIteration(val => val + 1)}><i className="fa fa-gear" /> Rebuild</button>
          </>
        }
        {props.latex_api === null && <p> Full LaTeX rendering non disponibile, solo anteprima HTML.</p>}
        <LaTeX text={text} />
        {lints && lints.length > 0 &&
          <>
            <hr />
            <h6>Suggerimenti stilistici</h6>
            {lints.map((lint, idx) => <LinterSuggerimento key={idx} fieldname={fieldname} suggerimento={lint} />)}
          </>
        }
      </div>
    </div>
  )
}


export default AnteprimaLaTeX;
